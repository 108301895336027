<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#inox-gradient-4);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>
    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 1630.54"
                    :y1="doorTopHeight1 + 7495.3"
                    :x2="doorLeftWidth1 + 1630.54"
                    :y2="doorTopHeight1 + 7425.3"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1672.58"
                    :y1="doorTopHeight1 + 7495.3"
                    :x2="doorLeftWidth1 + 1672.58"
                    :y2="doorTopHeight1 + 7425.3"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 48.88"
                    :y1="doorTopHeight1 + 159.73"
                    :x2="doorLeftWidth1 + 48.88"
                    :y2="doorTopHeight1 + 48.66"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 48.88"
                    :y1="doorTopHeight1 + 155.07"
                    :x2="doorLeftWidth1 + 48.88"
                    :y2="doorTopHeight1 + 53.33"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 90.93"
                    :y1="doorTopHeight1 + 159.73"
                    :x2="doorLeftWidth1 + 90.93"
                    :y2="doorTopHeight1 + 48.66"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 90.93"
                    :y1="doorTopHeight1 + 155.07"
                    :x2="doorLeftWidth1 + 90.93"
                    :y2="doorTopHeight1 + 53.33"
                    xlink:href="#glass-gradient"/>
    <!-- Molding gradient BEGIN -->
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <!-- Molding gradient END -->
    <g id="C12">
      <path id="inox"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 1616.66},${doorTopHeight1 + 7425.3}
            l-0.23,70
            h70.23
            v-70
            h-70
            Z
            m22.74,65.34
            h-17.73
            V${doorTopHeight1 + 7430}
            h59.73
            v60.67
            Z
            `"
            transform="translate(-1581.65 -7249.7)"/>
      <polygon id="inox-3"
               v-if="showInox"
               data-name="inox"
               class="cls-6"
               :points="`
               ${doorLeftWidth1 + 35} ${doorTopHeight1 + 48.66}
               ${doorLeftWidth1 + 63} ${doorTopHeight1 + 48.66}
               ${doorLeftWidth1 + 63} ${doorTopHeight1 + 159.73}
               ${doorLeftWidth1 + 34.77} ${doorTopHeight1 + 159.73}
               ${doorLeftWidth1 + 35} ${doorTopHeight1 + 48.66}
               `"/>
      <rect id="glass"
            class="cls-7"
            :x="doorLeftWidth1 + 40.02"
            :y="doorTopHeight1 + 53.33"
            width="17.73"
            height="101.74"/>
      <rect id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-8"
            :x="doorLeftWidth1 + 76.82"
            :y="doorTopHeight1 + 48.66"
            width="28.23"
            height="111.07"/>
      <rect id="glass-2"
            data-name="glass"
            class="cls-9"
            :x="doorLeftWidth1 + 82.07"
            :y="doorTopHeight1 + 53.33"
            width="17.73"
            height="101.74"/>
      <!-- Molding BEGIN -->
      <g id="Moldings" v-if="showMolding">
        <g id="Molding-1L" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 58.5} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 58.5} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 58.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 157.57}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 58.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 58.5} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 58.68} ${doorTopHeight1m + 157.57}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1m + 58.5} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 58.5} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 58.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 157.57}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1m + 58.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 58.5} ${doorTopHeight1m + 55.89 }
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 63.55} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 58.68} ${doorTopHeight1m + 157.57}
            `"/>
        </g>
        <g id="Molding-1R" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 100.51} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 82.03} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 100.51} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 82.2} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 100.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 82.2} ${doorTopHeight1m + 157.57}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 82.03} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 82.2} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 82.03} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 100.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 100.51} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 100.68} ${doorTopHeight1m + 157.57}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1m + 100.51} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 82.03} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 100.51} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1m + 82.2} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 100.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 82.2} ${doorTopHeight1m + 157.57}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1m + 82.03} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 82.2} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 77.25} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 82.03} ${doorTopHeight1m + 55.89}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1m + 100.68} ${doorTopHeight1m + 157.57}
              ${doorLeftWidth1m + 100.51} ${doorTopHeight1m + 55.89}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 51.46}
              ${doorLeftWidth1m + 105.46} ${doorTopHeight1m + 162}
              ${doorLeftWidth1m + 100.68} ${doorTopHeight1m + 157.57}
            `"/>
        </g>
        <g id="Molding-2" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 100.46} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 100.46} ${doorTopHeight1m + 181.97}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 100.64} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 242.82}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 181.97}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1m + 100.64} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 100.46} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 100.64} ${doorTopHeight1m + 242.82}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1m + 100.46} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 100.46} ${doorTopHeight1m + 181.97}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 100.64} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 242.82}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 39.85} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 34.8} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 39.67} ${doorTopHeight1m + 181.97}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1m + 100.64} ${doorTopHeight1m + 242.82}
              ${doorLeftWidth1m + 100.46} ${doorTopHeight1m + 181.97}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 177.53}
              ${doorLeftWidth1m + 105.51} ${doorTopHeight1m + 247.25}
              ${doorLeftWidth1m + 100.64} ${doorTopHeight1m + 242.82}
            `"/>
        </g>
      </g>
      <!-- Molding END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
    doorLeftWidth1m() {
      return this.doorLeftWidth + this.doorWidth/2 - 70.5
    },
    doorTopHeight1m() {
      return this.doorTopHeight + this.doorHeight/2 - 151
    },
  }
}
</script>
